import { Stack, Typography } from '@mui/material';
import { motion, Variants } from 'motion/react';

const animationVariant: Variants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      duration: 0.7,
    },
  },
};

const message = `Helaas kon je afgelopen zondag je 'X' en 'Gouden X' niet plaatsen. Gelukkig
          had dat geen invloed op de eindstand en de uiteindelijke winnaar van
          de ‘Gouden X’. De persoon die vorige week als eerste stond op het
          scorebord, kon niet meer voorbijgestoken worden.`;

const FinalMessageContent = () => {
  return (
    <motion.div
      initial="initial"
      animate="animate"
      transition={{ staggerChildren: 0.4 }}
      style={{ flex: 1, display: 'flex' }}
    >
      <Stack
        flex={1}
        textAlign="center"
        alignItems="center"
        sx={{ textWrap: 'balance' }}
        mt={3}
      >
        <Typography
          component={motion.h2}
          variants={animationVariant}
          variant="h3"
          mb={2}
        >
          Oeps, het ging mis
        </Typography>
        <Typography
          component={motion.p}
          variants={animationVariant}
          variant="paragraph1"
          fontWeight={400}
          mb={4}
          maxWidth={450}
          lineHeight={1.5}
        >
          {message}
        </Typography>
      </Stack>
    </motion.div>
  );
};

export default FinalMessageContent;
