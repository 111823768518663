import { Store } from 'store/reducers';

export const getTrackingEnabled = (state: Store) =>
  state.settings.trackingEnabled;

export const getDidSeeGoldenXIntro = (state: Store) =>
  state.settings.didSeeGoldenXIntro || false;

export const getDidSeeFinalMessage = (state: Store) =>
  state.settings.didSeeFinalMessage || false;

export const getWatchedTips = (state: Store) =>
  state.settings.watchedTips || [];
